<template>
  <div class="kcxfz-container">
    <div class="banner_kcxfz">
    <i style="background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/banner_kcxfz.jpg);"></i>
    <div class="margin width1480 hidden a-fdB">
        可持续发展
    </div>
</div>
<div class="margin width1480 hidden kcxfz_con">
    <ul>
        <li>
            <img src="https://img.cdn.wxzhida.cn/shzd-web-site/images/kcxfz1.png" class="i" />
            <div class="bg">
                <dd>
                    <p>关注碳排放</p>
                    <p>绿色节能可持续</p>
                </dd>
                <dl>产品包装改进，空调节能管控，循环利用周转箱，节能环保，锡渣回收，生产优化……</dl>
            </div>
        </li>
        <li>
            <img src="https://img.cdn.wxzhida.cn/shzd-web-site/images/kcxfz2.png" class="i" />
            <div class="bg">
                <dd>
                    <p>促进行业</p>
                    <p>交流与贡献</p>
                </dd>
                <dl>加入德国电动出行协会、 Charin 充电协议倡议协会等，促进技术交流，推动电动汽车和绿色能源进入全球家庭</dl>
            </div>
        </li>
        <li>
            <img src="https://img.cdn.wxzhida.cn/shzd-web-site/images/kcxfz3.png" class="i" />
            <div class="bg">
                <dd>
                    <p>积极慈善</p>
                    <p>参与社区建设</p>
                </dd>
                <dl>为大自然保护电动出行配套充电基础设施，疫情期间积极参与抗疫防疫</dl>
            </div>
        </li>
        <li>
            <img src="https://img.cdn.wxzhida.cn/shzd-web-site/images/kcxfz4.png" class="i" />
            <div class="bg">
                <dd>
                    <p>关爱员工</p>
                    <p>助力员工职业发展</p>
                </dd>
                <dl>建立培训体系，完善福利制度和内部沟通机制，帮助员工在行业中更好成长自我</dl>
            </div>
        </li>
        <li>
            <img src="https://img.cdn.wxzhida.cn/shzd-web-site/images/kcxfz5.png" class="i" />
            <div class="bg">
                <dd>
                    <p>完善内部治理</p>
                    <p>坚定挚达价值观</p>
                </dd>
                <dl>加强企业文化宣导和内部管理制度建设，倡导坚持、融合与创新</dl>
            </div>
        </li>
        <li>
            <img src="https://img.cdn.wxzhida.cn/shzd-web-site/images/kcxfz6.png" class="i" />
            <div class="bg">
                <dd>
                    <p>研发创新坚持不懈</p>
                    <p>质量管理精益求精</p>
                </dd>
                <dl>持续创新产品、模式与机制，提升硬件与服务质量，打造“车规级充电桩”</dl>
            </div>
        </li>
    </ul>
</div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive } from 'vue'
export default {
  name: 'kcxfz',
  components: {},
  setup() {
    const {proxy} = getCurrentInstance()
    onMounted(() => {
      document.title = '可持续发展'
      
      // 滚动到页面最上方
      window.scrollTo(0, 0);
      
    })
    return {}
  },
}
</script>

<style lang="less" scoped></style>
